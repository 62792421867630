import { render, staticRenderFns } from "./village.vue?vue&type=template&id=06a92a65&scoped=true&"
import script from "./village.vue?vue&type=script&lang=js&"
export * from "./village.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a92a65",
  null
  
)

export default component.exports